'use client';

import { OderSuccess } from '@/assets/lotties';
import Button from '@/components/atoms/Button';
import LottiePlayer from '@/components/atoms/LottiePlayer/LottiePlayer';
import Modal from '@/components/atoms/Modal';
// import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { FaCircleCheck } from 'react-icons/fa6';
import { MdCheckCircle, MdClose, MdError } from 'react-icons/md';
import { PiSealWarningFill } from 'react-icons/pi';
import dynamic from 'next/dynamic';
import styles from './action-modal.module.scss';
import HubSpotChat from '@/components/molecules/HubSpotChat/HubSpotChat';

const ProgressBar = dynamic(() => import('next-nprogress-bar').then((module) => module.AppProgressBar), {
	ssr: false
});

interface CustomModalProps {
	title?: string;
	message: string | ReactNode;
	type: 'success' | 'warning' | 'error' | 'info' | 'orderSuccess';
	confirmText?: string; // Make it optional
	onClose?: () => void; // Make it optional
	cancelText?: string; // Make it optional
	onConfirm?: () => void; // Make it optional
	onCancel?: () => void; // Make it optional
	provider?: 'alert' | 'toast';
	confirmDisabled?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
	title,
	message,
	type,
	confirmText = 'Ok',
	onClose,
	cancelText,
	onConfirm,
	onCancel,
	provider = 'alert',
	confirmDisabled = false
}) => {
	const handleCancel = () => {
		if (onCancel) onCancel();
		onClose();
	};

	const handleConfirm = () => {
		onClose();
		if (onConfirm) onConfirm();
	};
	const renderIcon = () => {
		switch (type) {
			case 'success':
				return <FaCircleCheck />;
			case 'warning':
				return <PiSealWarningFill />;
			case 'error':
				return <MdError />;
			case 'info':
				return <BsInfoCircleFill />;
			case 'orderSuccess':
				return <LottiePlayer animationData={OderSuccess} loop={false} autoplay={true} height='200px' />;
			default:
				return <PiSealWarningFill />;
		}
	};
	const renderIconNotify = () => {
		switch (type) {
			case 'success':
				return <MdCheckCircle size={24} />;
			case 'warning':
				return <PiSealWarningFill />;
			case 'error':
				return <PiSealWarningFill size={24} />;
			case 'info':
				return <PiSealWarningFill />;
			case 'orderSuccess':
				return <LottiePlayer animationData={OderSuccess} loop={false} autoplay={true} height='200px' />;
			default:
				return <PiSealWarningFill />;
		}
	};
	useEffect(() => {
		if (provider === 'toast') {
			const timeoutId = setTimeout(() => {
				onClose();
			}, 5000); // close after 2 seconds

			return () => clearTimeout(timeoutId); // cleanup timeout if the component is unmounted before the timeout completes
		}
	}, [provider, onClose]);

	return (
		<>
			{provider === 'alert' && (
				<Modal
					isOpen={true}
					onClose={onClose}
					footer={null}
					width={432}
					bodyStyle={{
						borderRadius: '15px'
					}}>
					<div className={styles.confirmModal}>
						<div className={`${styles.icon} ${styles[type]}`}>{renderIcon()}</div>

						<div className={styles.content}>
							<div className={styles.title}>{title}</div>
							{message && <div className={styles.message}>{message}</div>}
						</div>

						<div className={styles.footer}>
							{cancelText && (
								<Button
									label={cancelText}
									className={styles.button}
									type='light'
									size='medium'
									onClick={handleCancel}
								/>
							)}
							<Button
								label={confirmText}
								className={styles.button}
								type='primary'
								size='medium'
								disabled={confirmDisabled}
								onClick={handleConfirm}
							/>
						</div>
					</div>
				</Modal>
			)}
			{provider === 'toast' && (
				<div className={`${styles.toastModal} ${styles[type]}`}>
					<div className={`${styles.linearProgress} ${styles[type]}`}></div>
					<div className='d-flex justify-space-between items-center'>
						<div className='d-flex gap-10 items-center'>
							<div className={styles.icon}>{renderIconNotify()}</div>
							<div className='d-flex flex-column justify-content-center'>
								{title && (
									<div className='d-flex justify-content-center gap-10 mb-10'>
										<div className='text-16 text-grey900 fw-600'>{title}</div>
									</div>
								)}
								<div className='d-flex justify-content-center gap-10'>
									<div className='text-14 text-grey900 fw-400'>{message}</div>
								</div>
							</div>
						</div>

						<div className={styles.close} onClick={onClose}>
							<MdClose onClick={onClose} size={16} className='text-grey900 text-18' />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

interface AlertContextValue {
	success: (data: Omit<CustomModalProps, 'type'>) => void;
	close: () => void;
	warning: (data: Omit<CustomModalProps, 'type'>) => void;
	error: (data: Omit<CustomModalProps, 'type'>) => void;
	info: (data: Omit<CustomModalProps, 'type'>) => void;
	orderSuccess: (data: Omit<CustomModalProps, 'type'>) => void;
	updateConfirmStatus: any;
}

const AlertContext = createContext<AlertContextValue | undefined>(undefined);

interface AlertProviderProps {
	children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
	const [alertData, setAlertData] = useState<CustomModalProps | null>(null);
	const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
	const updateConfirmStatus = async (status: boolean) => {
		setIsConfirmDisabled(status || false);
		return status;
	};

	const success = (data: Omit<CustomModalProps, 'type'>) => {
		setAlertData({ ...data, type: 'success' });
	};

	const warning = (data: Omit<CustomModalProps, 'type'>) => {
		setAlertData({ ...data, type: 'warning' });
	};
	const error = (data: Omit<CustomModalProps, 'type'>) => {
		setAlertData({ ...data, type: 'error' });
	};
	const info = (data: Omit<CustomModalProps, 'type'>) => {
		setAlertData({ ...data, type: 'info' });
	};
	const orderSuccess = (data: Omit<CustomModalProps, 'type'>) => {
		setAlertData({ ...data, type: 'orderSuccess' });
	};

	const close = () => {
		setAlertData(null);
		setIsConfirmDisabled(false);
	};

	return (
		<AlertContext.Provider
			value={{
				success,
				close,
				warning,
				error,
				info,
				orderSuccess,
				updateConfirmStatus
			}}>
			{/* <HubSpotChat /> */}

			{children}
			{alertData && <CustomModal {...alertData} onClose={close} confirmDisabled={isConfirmDisabled} />}
			<ProgressBar options={{ showSpinner: false }} shallowRouting />
		</AlertContext.Provider>
	);
};

export const useAlert = () => {
	const context = useContext(AlertContext);
	if (context === undefined) {
		throw new Error('useAlert must be used within an AlertProvider');
	}

	return context;
};
